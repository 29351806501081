<template>
  <section>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="two-rows-wrapper">
      <div class="white-container">
        <h5>{{ $store.getters.GET_LANG ? 'Нормативная база' : 'Normative base' }}</h5>
        <tricolor-line />
        <div class="block-structure" v-for="(item, num) of contentLink" :key="'id_'+num" :id="'corruption'+item.title">
          <p class="title_structure">{{item.title}}</p>
          <ul>
            <li v-for="(index, i) of item.content" :key="i">
              <a :href="index.link" download target="_blank" v-show="!index.website">
                {{index.text}}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <aside class="two-rows-navigation">
        <h2>{{ $store.getters.GET_LANG ? 'Навигация по странице' : 'Page navigation' }}</h2>
        <nav>
          <a v-for="(nav, i, index) in contentLink" :key="index" :href="`#corruption${nav.title}`">{{ nav.title }}</a>
          <!--          <router-link v-for="(nav, i, index) in anchorNavigation" :key="index" :to="{ path: '/', hash: `nav_${index}` }">{{ nav }}</router-link>-->
        </nav>
      </aside>
    </div>
  </section>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import Uniq from '@/methods/uniq'
export default {
  name: 'AntiCorruptionMeasures',
  data () {
    return {
      contentLink: []
    }
  },
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Нормативная база' : 'Normative base'
        }
      ]
    },
    getNormativeBase () {
      return this.$store.getters.getNormativeBase
    }
  },
  methods: {
    getData () {
      this.$store.dispatch('setNormativeBase', {
        headers: {
          Localization: this.$store.getters.GET_LANG ? 'ru' : 'eng'
        }
      })
    },
    sortArray () {
      var data = JSON.parse(JSON.stringify(this.$store.getters.getNormativeBase))
      function customSort (a, s) {
        return a.sort(function (x1, x2) {
          var i1 = s.indexOf(x1.tag_list[0])
          var i2 = s.indexOf(x2.tag_list[0])
          return i1 < 0 ? 1 : i2 < 0 ? -1 : i1 - i2
        })
      }
      customSort(data, ['Основные законы', 'Регламент', 'Кодекс этики', 'Федеральные законы', 'Указы Президента РФ', 'Постановления Правительства РФ', 'Положения', 'Иные документы'])
      const prepeareArray = []
      let uniq = []
      data.map(item => {
        item.tag_list.map(obj => {
          uniq.push(obj)
        })
      })
      uniq = Uniq(uniq)
      uniq.map(item => {
        const title = item
        const content = []
        data.map(val => {
          val.tag_list.map(tag => {
            if (tag === item) {
              content.push({
                text: val.title,
                link: val.link === null ? val.file : val.link
              })
            }
          })
        })
        prepeareArray.push({ title, content })
      })
      this.contentLink = prepeareArray
    }
  },
  mounted () {
    this.getData()
    document.title = 'Нормативная база'
  },
  watch: {
    getNormativeBase () {
      this.sortArray()
    },
    '$store.getters.GET_LANG' (value) {
      this.getData()
    }
  },
  components: {
    BreadCrumb,
    TricolorLine
  }
}
</script>
<style lang="scss" scoped>
.white-container{
  width: 61.44rem;
}
h5{
  font-weight: 600;
  font-size: 1.38rem;
  line-height: 1.75rem;
  color: #1F3245;
  margin-bottom: 1.875rem;
}
.block-structure{ margin-top: 3.125rem; }
#id_0{ margin-top: 1.875rem;}

.title_structure{
  width: 100%;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #1F3245;
  opacity: 0.8;
  padding-bottom: 0.5rem;
}

ul > li{
  list-style-type: disc;
  color: #246CB7;
  margin:  0.75rem 0 0 1rem;
}
a{
  font-size: 0.875rem;
  line-height: 1.38rem;
  color: #246CB7;
  margin-top: 0.75rem;
}
.website_img{
  padding-right: 1.56rem;
  position: relative;
}
.website_img span{
  background: url('../../assets/icons/link_anticorrop.svg') center center no-repeat;
  background-size: .813rem;
  padding: .1rem 0.6rem;
  margin-left: .7rem;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
}
@media screen and (max-width: 420px){
  .white-container{
    width: 100%;
    margin: 0;
    padding: 1.88rem 1rem;
  }
  ul > li {
    margin: .95rem 0 0 .76rem;
    font-size: .64rem;
  }
  // .website_img:before {
  //     bottom: .2rem;
  //     top: auto;
  // }
}
</style>
